<template>
  <div class="survey">
    <b-form>
      <b-form-group label-cols="3" :label="title">
        <b-row class="form-inner-row">
          <b-col class="form-inner-col">
            <b-form-radio-group v-model="q.answer[0].data">
              <b-form-radio value="시행">시행</b-form-radio>
              <b-form-radio value="아니오">아니오</b-form-radio>
              <b-form-radio value="모름">모름</b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>
        <b-row class="form-inner-row" :class="q.answer[0].data !== '시행' ? 'disabled' : ''">
          <b-col class="form-inner-col">
            <label>{{ q.answer[1].label[0] }}</label>
            <b-form-input type="text" v-model="q.answer[1].data[0]" />
          </b-col>
          <b-col class="form-inner-col">
            <label>{{ q.answer[1].label[1] }}</label>
            <b-form-input type="text" v-model="q.answer[1].data[1]" />
          </b-col>
        </b-row>
        <b-row class="form-inner-row" :class="q.answer[0].data !== '모름' ? 'disabled' : ''">
          <b-col class="form-inner-col">
            <label>{{ q.answer[2].label }}</label>
            <b-form-input type="text" v-model="q.answer[2].data" class="input-lg"/>
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </div>
</template>
<script>
export default {
  name: 'Q34',
  model: {
    prop: 'q',
  },
  props: ['q'],
  data() {
    return {
      title: '당화혈색소 수치',
    };
  },
  updated() {
    if (this.q === '') {
      this.q = {
        title: '당화혈색소 수치',
        answer: [
          {
            data: '',
          },
          {
            label: ['결과', '시행시기'],
            data: ['', ''],
          },
          {
            label: '이유',
            data: ''
          }
        ],
      };
    }
    if (this.q.answer[0].data === '아니오' || this.q.answer[0].data === '모름') {
      // '아니오' 또는 '모름' 선택시 '시행' 하위의 질문 답변 초기화
      while (this.q.answer[1].data.length) {
        this.q.answer[1].data.pop();
      }
    }
    if (this.q.answer[0].data === '아니오' || this.q.answer[0].data === '예') {
      // '아니오' 또는 '예' 선택시 '시행' 하위의 질문 답변 초기화
      this.q.answer[2].data = ''
    }
  },
};
</script>